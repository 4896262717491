import React from 'react';
import { Link as RouterLink, useLocation } from 'react-router-dom';
import MenuIcon from '@mui/icons-material/Menu';
import { IconButton, Menu, MenuItem, Tab, Tabs } from '@mui/material';
import { mainMenu } from '../../navigation';

interface IProps {
  isDesktop?: boolean;
}

export const MainMenu: React.FC<IProps> = ({ isDesktop = true }) => {
  const { pathname } = useLocation();

  const [anchorElNav, setAnchorElNav] = React.useState<null | HTMLElement>(null);

  const handleDropdownOpen = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElNav(event.currentTarget);
  };

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };

  const pathMatch = pathname.match(/^\/[^\/]*/);

  return isDesktop ? (
    <Tabs
      value={pathMatch?.length ? pathMatch[0] : ''}
      textColor="inherit"
      indicatorColor="secondary"
      component="nav"
      sx={{ display: 'flex', flexGrow: 1 }}
    >
      {mainMenu.items.map((item, index) => (
        <Tab key={index} label={item.label} component={RouterLink} to={item.url} value={item.url} />
      ))}
    </Tabs>
  ) : (
    <>
      <IconButton
        size="large"
        aria-label="Menu"
        aria-controls="menu-appbar"
        aria-haspopup="true"
        onClick={handleDropdownOpen}
        color="inherit"
      >
        <MenuIcon />
      </IconButton>
      <Menu
        id="menu-appbar"
        anchorEl={anchorElNav}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        keepMounted
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        open={Boolean(anchorElNav)}
        onClose={handleCloseNavMenu}
        sx={{
          display: { xs: 'block', md: 'none' },
        }}
      >
        {mainMenu.items.map((item, index) => (
          <MenuItem key={index} onClick={handleCloseNavMenu} component={RouterLink} to={item.url}>
            {item.label}
          </MenuItem>
        ))}
      </Menu>
    </>
  );
};
