import React, { PropsWithChildren } from 'react';
import { ListNoResults, useListContext } from 'react-admin';

export const ListLoader = ({ children }: PropsWithChildren<unknown>) => {
  const { isPending, total } = useListContext();

  if (isPending) {
    return null;
  }

  if (total === 0) {
    return <ListNoResults />;
  }

  return children;
};
