const ruMessages = {
  orders: {
    name: 'Заказ |||| Заказы',
    empty: 'Нет заказов',
    fields: {
      dttm: 'Дата заказа',
    },
    notification: {
      created: 'Заказ создан успешно',
    },
  },
};

export default ruMessages;
