import React from 'react';
import { Box, Card, CardMedia, Stack, Typography } from '@mui/material';
import { useShowController, RecordContextProvider, SimpleShowLayout, TextField } from 'react-admin';
import { IInputQtyProps, InputQty } from './InputQty';
import { numAsCurrency } from '../../utils';

type IProps = IInputQtyProps; // alias for future

export const GoodShow = (props: IProps) => {
  const { record: rec, isLoading } = useShowController();

  if (!rec || isLoading) {
    return <h1>Loading...</h1>;
  }

  return (
    <RecordContextProvider value={rec}>
      <Card sx={{ my: 2 }}>
        <Typography
          variant="h5"
          sx={{ px: 2, pt: 2, fontWeight: 'bolder', textDecoration: 'none' }}
        >
          {rec.name}
        </Typography>
        <Stack direction="row">
          <Box
            sx={{
              p: 2,
              flex: '0 0 400px',
              // background: `url(${NoImage}) center center / 80% no-repeat`,
            }}
          >
            <CardMedia
              component="img"
              // image="https://www.canvass.warnermusic.ru/Handlers/GetAlbumCoverHandler.ashx?imgpath=;2017\2017_03\OST%20-%20RESIDENT%20EVIL%20THE%20FINAL%20CHAPTER.jpg"
              // image={`http://pub.m-zvuka.ru:8080/picture/${rec.id}.jpg`}
              image={`https://b2b.m-zvuka.ru/pictures/${rec.id}.jpg`}
              alt="Нет картинки"
            />
          </Box>
          <SimpleShowLayout>
            <TextField label="Артикул" source="code" />
            <TextField label="Штрих-код" source="barcode" />
            {rec.release_dtstr && <TextField label="Релиз" source="release_dtstr" />}
            {rec.info && <TextField label="Описание" source="info" />}
            <Typography variant="h5">
              {rec.price?.value ? numAsCurrency(rec.price.value) : '?'}
            </Typography>
            <InputQty rec={rec} {...props} />
          </SimpleShowLayout>
        </Stack>
      </Card>
    </RecordContextProvider>
  );
};
