import { IMenuData } from './types';

export const mainMenu: IMenuData = {
  items: [
    {
      label: 'Главная',
      url: '/',
    },
    {
      label: 'Каталог',
      url: '/catalog',
    },
    {
      label: 'Корзина',
      url: '/cart',
    },
    {
      label: 'Заказы',
      url: '/orders',
    },
    {
      label: 'Канвасы',
      url: '/canvass',
    },
    {
      label: 'Предзаказ',
      url: '/wishlist',
    },
    {
      label: 'Offers',
      url: '/offers',
    },
  ],
};
