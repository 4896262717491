import React from 'react';
import { ListNoResults, RecordContextProvider, useListContext } from 'react-admin';
import { OfferListItem } from './OfferListItem';

export const OfferListView = () => {
  const { data, isPending, total } = useListContext();

  if (isPending) {
    return null;
  }

  if (Number(total) === 0) {
    return <ListNoResults />;
  }

  return data?.map((record) => (
    <RecordContextProvider key={record.id} value={record}>
      <OfferListItem />
    </RecordContextProvider>
  ));
};
