/**
 *
 */
const extractSaveAs = {
  fromHeader: (response: Response) => {
    const contentDisposition = response.headers.get('content-disposition');
    const fnKey = 'FILENAME';
    const fnRe = new RegExp(`filename=(?<${fnKey}>[^;]*)`);
    return contentDisposition?.match(fnRe)?.groups?.[fnKey];
  },

  fromUrl: (url: string) => {
    const [...parts] = url.split('/');
    return parts.pop();
  },
};

/**
 *
 */
export const downloadFile = (url: string, options?: RequestInit, saveAs?: string) => {
  fetch(url, {
    method: 'GET',
    ...options,
    // responseType: 'blob', // important
  })
    .then((response) => {
      const filename = saveAs ?? extractSaveAs.fromHeader(response) ?? extractSaveAs.fromUrl(url);
      if (!filename) {
        throw 'Неопределенно имя принимаемого файла.';
      }
      return response.blob().then((blob) => ({
        filename,
        blob,
      }));
    })
    .then(({ filename, blob }) => {
      // create file link in browser's memory
      const href = URL.createObjectURL(blob);

      // create "a" HTML element with href to file & click
      const link = document.createElement('a');
      link.href = href;
      link.download = filename; //or any other extension
      document.body.appendChild(link);
      link.click();

      // clean up "a" element & remove ObjectURL
      document.body.removeChild(link);
      URL.revokeObjectURL(href);
    });
};
