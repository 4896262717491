import { WishlistList } from './WishlistList';
import { RESOURCE_WISHLIST } from './constants';

export default {
  name: RESOURCE_WISHLIST,
  // options: { label: 'Товары' },
  list: WishlistList,
};

export * from './constants';
export * from './types';
