// export const API_HOST = process.env.REACT_APP_API_HOST ?? '';
export const APP_VERSION = '0.3.1';
export const APP_HEADER_TITLE = 'MZ-UI';
// export const APP_LOGO_TEXT = 'Элтос';
// export const APP_VERSION = '2023.11.1';
export const APP_BUILD_DATE = '21-06-2024';

export const config = {
  apiHost: process.env.REACT_APP_API_HOST ?? '',
  apiPath: process.env.REACT_APP_API_PATH ?? '',
  apiAuthPath: '/api/auth',
};
