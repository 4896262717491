import React from 'react';
import {
  FilterButton,
  ListProps,
  Pagination,
  PaginationProps,
  SearchInput,
  SortButton,
  TextInput,
  TopToolbar,
} from 'react-admin';
import { List, ListLoader } from '../../components/List';
import { TListViewMode, ViewModeToggler } from '../../components/ViewModeToggler';
import { SwitchInputFilter } from '../../components/SwitchInputFilter';
import { GoodGridView } from './GoodGridView';
import { GoodTableView } from './GoodTableView';
import { IInputQtyProps } from './InputQty';

const CustPagination = (props: PaginationProps) => (
  <Pagination rowsPerPageOptions={[10, 20, 50, 100]} {...props} />
);

const listFilters = [
  /*
                  <SelectInput
                    key="list-type"
                    name="list-type"
                    label="Тип списка"
                    source="list-type"
                    emptyText="<Все>"
                    choices={[
                      { id: 'canorder', name: 'Не розданные' },
                      { id: 'cannotorder', name: 'Розданные' },
                      { id: 'canceled', name: 'Отмененные' },
                    ]}
                    variant="outlined"
                    alwaysOn
                  />,
                   */
  <SearchInput
    key="search"
    name="search"
    source="name"
    alwaysOn
    // variant="outlined"
    //  size="small"
    sx={{ width: '10rem' }}
  />,
  <SwitchInputFilter key="in_stock" name="in_stock" label="В наличии" checkedValue={1} alwaysOn />,
  <TextInput
    key="code"
    name="code"
    source="code"
    label="Артикул"
    // variant="outlined"
    // size="small"
    resettable
    sx={{ width: '10rem' }}
  />,
  <TextInput
    key="barcode"
    name="barcode"
    label="Штрих-код"
    source="barcode"
    // variant="outlined"
    // size="small"
    resettable
    sx={{ width: '10rem' }}
  />,
];

const ListActions = (props: {
  viewMode: TListViewMode;
  setViewMode: (value: TListViewMode) => void;
}) => {
  return (
    <TopToolbar
    // sx={{ alignItems: 'center' }}
    >
      <FilterButton />
      <SortButton fields={['new']} />
      {/*
      <SelectColumnsButton />
      */}
      <ViewModeToggler {...props} />
    </TopToolbar>
  );
};

export interface IGoodListProps extends Omit<ListProps, 'children'>, IInputQtyProps {}

export const GoodList = (props: IGoodListProps) => {
  const [viewMode, setViewMode] = React.useState<TListViewMode>('grid');
  return (
    <List
      exporter={false}
      actions={<ListActions viewMode={viewMode} setViewMode={setViewMode} />}
      filters={listFilters}
      // title={<Title subTitle="Товары и цены" args={{}} />}
      pagination={<CustPagination />}
      perPage={10}
      queryOptions={{ meta: { test: 1 } }}
      sort={{ field: 'code', order: 'ASC' }}
      {...props}
    >
      <ListLoader>
        {viewMode == 'grid' ? <GoodGridView {...props} /> : <GoodTableView {...props} />}
      </ListLoader>
    </List>
  );
};
