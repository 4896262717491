import { OfferList } from './OfferList';
import { RESOURCE_OFFERS } from './constants';

export default {
  name: RESOURCE_OFFERS,
  // options: { label: 'Товары' },
  list: OfferList,
};

export * from './constants';
