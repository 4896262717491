const ruMessages = {
  offers: {
    name: 'Offer |||| Offers',
    empty: 'Список пуст',
    fields: {
      code: 'Код',
      name: 'Наименование',
    },
  },
};

export default ruMessages;
