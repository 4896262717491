import React from 'react';
import { Stack, Typography } from '@mui/material';
import {
  Create,
  // required,
  SaveButton,
  SimpleForm,
  TextInput,
  Toolbar as RaToolbar,
  ToolbarProps,
  useNotify,
  useRedirect,
  useResourceContext,
} from 'react-admin';
import Grid from '@mui/material/Unstable_Grid2';
import { CartTotals } from './CartTotals';

const Toolbar = (props: ToolbarProps) => {
  const resource = useResourceContext();
  const notify = useNotify();
  const redirect = useRedirect();
  return (
    <RaToolbar {...props}>
      <SaveButton
        type="button"
        label="Оформить заказ"
        color="success"
        alwaysEnable
        mutationOptions={{
          onSuccess: () => {
            notify('resources.orders.notification.created', { type: 'success' });
            redirect(`/${resource}`);
          },
        }}
      />
    </RaToolbar>
  );
};

export const CartOrder = () => {
  return (
    <Create resource="orders" redirect="list" sx={{ flexGrow: 1 }}>
      <SimpleForm toolbar={<Toolbar />}>
        <Grid container justifyContent="space-between" width="100%">
          <Typography variant="h6" gutterBottom>
            Заказ
          </Typography>
          <Stack gap={3} direction="row" alignItems="baseline">
            <Typography variant="h6" component="div" sx={{ lineHeight: '1.6' }}>
              Итого
            </Typography>
            <CartTotals />
          </Stack>
        </Grid>
        <TextInput
          name="remark"
          source="remark"
          label="Примечание"
          variant="outlined"
          // validate={required()}
          sx={{ width: '100%' }}
        />
      </SimpleForm>
    </Create>
  );
};
