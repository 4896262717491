import { useDataProvider, useNotify, useResourceContext } from 'react-admin';
import { useMutation, useQueryClient } from '@tanstack/react-query';

/**
 *
 */
export const useRecMutate = (
  url: string,
  id: number | string,
  refreshProc: (rec: any, vars: any) => void,
) => {
  const resourceName = useResourceContext();
  const notify = useNotify();

  const queryClient = useQueryClient();
  const dataProvider = useDataProvider();
  return useMutation({
    mutationFn: (rec: any) => {
      // console.log('== mutate rec', rec);
      return dataProvider.create(url, {
        data: rec,
      });
    },
    onError: (error) => {
      notify(String(error), { type: 'error', autoHideDuration: 3000 });
    },
    onSuccess: (data, vars) => {
      const qk = [resourceName, 'getOne', { id: String(id) }];
      const recOne = queryClient.getQueryData(qk) as any;
      if (recOne) {
        refreshProc(recOne, vars);
        queryClient.setQueryData(qk, recOne);
      } else {
        queryClient.invalidateQueries({ queryKey: [resourceName] });
      }
    },
  });
};
