import React from 'react';
// import { Table, TableBody, TableCell, TableHead, TableRow } from '@mui/material';
import {
  Datagrid,
  FunctionField,
  NumberField,
  RaRecord,
  TextField,
  useRecordContext,
} from 'react-admin';
import { getCurrencyOpts, numAsCurrency } from '../../utils';

export const OrderItems = () => {
  const record = useRecordContext();

  /*
  const cols = React.useRef<GridColDef[]>([
    { field: 'good.code', headerName: 'Артикул' },
    { field: 'good.barcode', headerName: 'Штрих-код' },
    { field: 'good.name', headerName: 'Наименование' },
    { field: 'quant', headerName: 'Кол-во' },
    { field: 'price_i', headerName: 'Цена' },
  ]);
   */

  return (
    record?.items && (
      <>
        <Datagrid
          optimized={false}
          data={record.items}
          bulkActionButtons={false}
          // sort={{ field: 'good.barcode', order: 'DESC' }}
          // setSort={(sort) => console.log('== sort', sort)}
        >
          <TextField source="good.code" label="Артикул" sortable={false} />
          <TextField source="good.barcode" label="Штрих-код" sortable={false} />
          <TextField source="good.name" label="Наименование" sortable={false} />
          <NumberField source="quant" label="Кол-во" sortable={false} />
          <NumberField source="price_i" label="Цена" sortable={false} options={getCurrencyOpts()} />
          <FunctionField
            source="sum_i"
            sortable={false}
            label="Сумма"
            textAlign="right"
            render={(record: RaRecord) => record && numAsCurrency(record.quant * record.price_i)}
          />
        </Datagrid>
        {/*
        <DataGrid rowHeight={32} columns={cols.current} rows={record.items} autoHeight={true} />
        */}
        {/*
        <Table size="small">
          <TableHead>
            <TableRow>
              <TableCell>Артикул</TableCell>
              <TableCell>Штрих</TableCell>
              <TableCell>Наименование</TableCell>
              <TableCell align="right">Кол-во</TableCell>
              <TableCell align="right">Цена</TableCell>
              <TableCell align="right">Сумма</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {record.items.map((item: any) => (
              <TableRow key={item.id} hover>
                <TableCell>{item.good.code}</TableCell>
                <TableCell>{item.good.barcode}</TableCell>
                <TableCell>{item.good.name}</TableCell>
                <TableCell align="right">{item.quant}</TableCell>
                <TableCell align="right">{item.price_i}</TableCell>
                <TableCell align="right">{item.quant * item.price_i}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
        */}
      </>
    )
  );
};
