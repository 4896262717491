import { OrderList } from './OrderList';
import { OrderShow } from './OrderShow';
import { RESOURCE_ORDERS } from './constants';

export default {
  name: RESOURCE_ORDERS,
  // options: { label: 'Товары' },
  list: OrderList,
  show: OrderShow,
};

export * from './constants';
