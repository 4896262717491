import React from 'react';
import Grid from '@mui/material/Unstable_Grid2';
import { useListContext } from 'react-admin';
import { WishlistTotals } from './WishlistTotals';
import { Card, CardContent, Stack, Typography } from '@mui/material';

export const WishlistListBottom = () => {
  const { total, isLoading, filterValues } = useListContext();

  if (!isLoading && total === 0 && !Object.keys(filterValues).length) {
    return null;
  }

  return (
    <Grid container justifyContent="flex-end" sx={{ mt: 2 }}>
      <Card>
        <CardContent>
          <Stack gap={3} direction="row" alignItems="baseline">
            <Typography variant="h6" component="div" sx={{ lineHeight: '1.6' }}>
              Итого
            </Typography>
            <WishlistTotals />
          </Stack>
        </CardContent>
      </Card>
    </Grid>
  );
};
