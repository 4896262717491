const ruMessages = {
  catalog: {
    name: 'Каталог |||| Каталог',
    empty: 'Список пуст',
    fields: {
      name: 'Наименование',
      code: 'Артикул',
      barcode: 'Штрих-код',
      new: 'Новинки',
    },
  },
};

export default ruMessages;
