import { CartList } from './CartList';
import { RESOURCE_CART } from './constants';

export default {
  name: RESOURCE_CART,
  // options: { label: 'Товары' },
  list: CartList,
};

export * from './constants';
export * from './types';
