import React from 'react';
import { Box, Divider, ListItemButton, Stack, Typography } from '@mui/material';
import { useRecordContext } from 'react-admin';
import { config } from '../../config';
import { downloadFile } from '../../utils';

const OFFERS_STORE_URL = `${config.apiHost}/store/offers`;

const ICONS_URL = `${process.env.PUBLIC_URL}/icons/ext`;

export const OfferListItem = () => {
  const record = useRecordContext();

  if (!record) {
    return null;
  }

  return (
    <>
      <ListItemButton
        sx={{ display: 'block' }}
        onClick={() => downloadFile(`${OFFERS_STORE_URL}/${record.name}`)}
      >
        <Typography variant="h5" component="h3" sx={{ fontWeight: 'bold' }}>
          {record.s1}
        </Typography>
        <Stack direction="row" spacing={2}>
          <Box sx={{ pt: 2 }}>
            <img
              style={{ width: '48px', height: '48px' }}
              src={`${ICONS_URL}/xls.svg`}
              alt="more"
            />
          </Box>
          <div>
            <Typography variant="h6" sx={{ fontWeight: 'bold' }}>
              {record.s2}
            </Typography>
            <div>{record.s3}</div>
            <Typography variant="subtitle2">{record.name}</Typography>
          </div>
        </Stack>
      </ListItemButton>
      <Divider orientation="horizontal" />
    </>
  );
};
