import React from 'react';
import Grid from '@mui/material/Unstable_Grid2';
import { Title as RATitle } from 'react-admin';
import { Title } from '../../components/List/Title';
import { CartTotalsWidget } from '../cart/CartTotals';
import { WishlistTotalsWidget } from '../wishlist/WishlistTotals';

const Dashboard = () => {
  // const { authenticated } = useAuthState();
  // console.log('== authenticated', authenticated);
  // {authenticated &&
  // }

  return (
    <>
      <RATitle title={<Title subTitle="Главная" args={{}} />} />
      <Grid container gap={3} sx={{ my: 3 }}>
        <CartTotalsWidget />
        <WishlistTotalsWidget />
      </Grid>
    </>
  );
};

export default Dashboard;
