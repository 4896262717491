import React from 'react';
import { useNotify } from 'react-admin';
import { useNavigate } from 'react-router-dom';
import { Box, Button, Card, CardActions, CardContent, CardHeader } from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2';
import { useApiFetch } from '../../hooks';
import { IWishlistTotalsDto } from './types';
import { RESOURCE_WISHLIST, URL_WISHLIST_TOTALS } from './constants';
import { numAsCurrency } from '../../utils';

export const WishlistTotals = () => {
  const notify = useNotify();
  const { data } = useApiFetch(URL_WISHLIST_TOTALS, {
    select: (data) => (data as any).data as IWishlistTotalsDto,
    throwOnError: (error) => {
      notify(String(error), { type: 'error', autoHideDuration: 3000 });
      return false;
    },
  });

  return (
    <Grid container gap={2}>
      <Grid>
        Кол-во:
        <Box component="span" sx={{ ml: 1, fontWeight: 'bold' }}>
          {(data as any)?.total_quant ?? '?'}
        </Box>
      </Grid>
      <Grid>
        Сумма:
        <Box component="span" sx={{ ml: 1, fontWeight: 'bold' }}>
          {(data as any)?.total_sum_i ? numAsCurrency((data as any).total_sum_i) : '?'}
        </Box>
      </Grid>
    </Grid>
  );
};

export const WishlistTotalsWidget = () => {
  const navigate = useNavigate();
  return (
    <Card>
      <CardHeader title="Предзаказ" titleTypographyProps={{ variant: 'h6' }} sx={{ pb: 0 }} />
      <CardContent sx={{ mx: 3 }}>
        <WishlistTotals />
      </CardContent>
      <CardActions>
        <Button size="small" onClick={() => navigate(`/${RESOURCE_WISHLIST}`)}>
          Перейти
        </Button>
      </CardActions>
    </Card>
  );
};
