import React from 'react';
import { ListProps } from 'react-admin';
import { OfferListView } from './OfferListView';
import { List } from '../../components/List';

export const OfferList: React.FC<ListProps> = (props) => {
  return (
    <List
      actions={false}
      perPage={10}
      sort={{ field: 'nn', order: 'ASC' }}
      sx={{ mt: 2 }}
      {...props}
    >
      <OfferListView />
    </List>
  );
};
