import { useQuery, UseQueryOptions, UseQueryResult } from '@tanstack/react-query';
import { apiFetch } from '../dataProvider';

/**
 *
 */
export const useApiFetch = <RecordType = any>(
  url: string,
  options?: Omit<UseQueryOptions<RecordType>, 'queryKey'>,
): UseQueryResult<RecordType> => {
  return useQuery<RecordType>({
    queryKey: [url],
    queryFn: () => apiFetch<RecordType>(url),
    ...options,
  });
};
