import React from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { useCreatePath, useListContext, useResourceContext } from 'react-admin';
import { Box, Card, CardContent, CardMedia, Stack, Typography } from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2';
import NoImage from '../../assets/images/no-image.svg';
import { InputQty } from './InputQty';
import { IGoodListProps } from './GoodList';
import { numAsCurrency } from '../../utils';

export const GoodGridView = (props: IGoodListProps) => {
  const resourceName = useResourceContext();
  const { data } = useListContext();
  const createPath = useCreatePath();
  return (
    data && (
      <Grid container spacing={2} sx={{ p: 2 }}>
        {data.map((rec: any) => {
          const urlShow = createPath({ resource: resourceName, id: rec.id, type: 'show' });
          return (
            <Grid key={rec.id} xs={12} sm={12} md={6} lg={6}>
              <Card key={rec.id} variant="outlined">
                <Stack direction="row">
                  <Box
                    component={RouterLink}
                    to={urlShow}
                    sx={{
                      flex: '0 0 200px',
                      minHeight: 200,
                      background: `url(${NoImage}) center center / 80% no-repeat`,
                    }}
                  >
                    <CardMedia
                      component="img"
                      // image="https://www.canvass.warnermusic.ru/Handlers/GetAlbumCoverHandler.ashx?imgpath=;2017\2017_03\OST%20-%20RESIDENT%20EVIL%20THE%20FINAL%20CHAPTER.jpg"
                      // image={`http://pub.m-zvuka.ru:8080/picture/${rec.id}.jpg`}
                      image={`https://b2b.m-zvuka.ru/pictures/${rec.id}.jpg`}
                      alt={rec.code}
                    />
                  </Box>
                  <CardContent>
                    <Typography
                      variant="body1"
                      sx={{ fontWeight: 'bolder', textDecoration: 'none' }}
                      component={RouterLink}
                      to={urlShow}
                    >
                      {rec.name}
                    </Typography>
                    <Typography variant="body2" sx={{ mt: '4px' }}>
                      Артикул: {rec.code}
                    </Typography>
                    <Typography variant="body2" sx={{ mt: '4px' }}>
                      Штрих-код: {rec.barcode}
                    </Typography>
                    {rec.release_dtstr && (
                      <Typography variant="body2" sx={{ mt: '4px' }}>
                        Релиз: {rec.release_dtstr}
                      </Typography>
                    )}
                    <Typography variant="h5">
                      {rec.price?.value ? numAsCurrency(rec.price.value) : '?'}
                    </Typography>
                    <InputQty rec={rec} {...props} />
                  </CardContent>
                </Stack>
              </Card>
            </Grid>
          );
        })}
      </Grid>
    )
  );
};
